import React, { useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageInfoProps, PublicationProps } from '../types/pages'
// Components
import { Layout, Seo, ContentMd } from '../components/commons'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Text } from '../atoms'

interface DataQuery {
  datoCmsLegal: {
    pageInfo: PageInfoProps
    locale: string
    meta: PublicationProps
    name: string
    headline: string
    lastUpdatedLabel: string
    updatedDate: string
    content: string
  }
}

const Legal: React.FC<PageProps<DataQuery>> = ({ data }) => {
  const {
    datoCmsLegal: { locale, meta, name, pageInfo, headline, lastUpdatedLabel, updatedDate, content },
  } = data

  const renderers = useMemo(() => {
    return {
      p: (props: any) => (
        <Box my={3}>
          <Text {...props} />
        </Box>
      ),
      h1: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h2'}
            fontSize={['28px', '32px', '32px']}
            fontWeight={'semi'}
            lineHeight={['32px', '40px', '40px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h2: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h2'}
            fontSize={['24px', '28px', '28px']}
            fontWeight={'semi'}
            lineHeight={['32px', '34px', '34px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h3: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h3'}
            fontSize={['20px', '24px', '24px']}
            fontWeight={'semi'}
            lineHeight={['24px', '32px', '32px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h4: ({ level, ...otherProps }: any) => (
        <Box mt={8} mb={6}>
          <Text
            as={'h4'}
            fontSize={['20px', '24px', '24px']}
            lineHeight={['24px', '32px', '32px']}
            fontFamily={'hoves'}
            color={'navy'}
            {...otherProps}
          />
        </Box>
      ),
      h5: ({ level, ...otherProps }: any) => (
        <Box mt={7} mb={6}>
          <Text
            as={'h5'}
            fontSize={['18px', '20px', '20px']}
            fontWeight={'semi'}
            lineHeight={['20px', '24px', '24px']}
            fontFamily={'hoves'}
            {...otherProps}
          />
        </Box>
      ),
      h6: ({ level, ...otherProps }: any) => (
        <Box mt={7} mb={6}>
          <Text
            as={'h5'}
            fontSize={['18px', '20px', '20px']}
            lineHeight={['20px', '24px', '24px']}
            fontFamily={'hoves'}
            color={'navy'}
            {...otherProps}
          />
        </Box>
      ),
    }
  }, [])

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />
      <Box pt={[120, 160]}>
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} sm={10} md={8}>
              {/* Hero */}
              <Box>
                <Heading fontSize={['32px', '40px', '56px']} lineHeight={['40px', '48px', '64px']}>
                  {!!headline ? headline : name}
                </Heading>
                {!!updatedDate && (
                  <Box mt={4}>
                    r
                    <Text fontWeight={'semi'}>
                      {!!lastUpdatedLabel ? lastUpdatedLabel : ''}
                      <Text as={'span'} fontWeight={'light'} fontSize={'inherit'}>
                        {updatedDate}
                      </Text>
                    </Text>
                  </Box>
                )}
              </Box>
              {/* Content */}
              {!!content && (
                <Box mt={10}>
                  <ContentMd content={content} renderers={renderers} />
                </Box>
              )}
            </Col>
          </Row>
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query LegalQuery($id: String!) {
    datoCmsLegal(id: { eq: $id }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      name
      pageInfo {
        ...pageInfoFragment
      }
      headline
      lastUpdatedLabel
      updatedDate(formatString: "ll")
      content
    }
  }
`

export default Legal
